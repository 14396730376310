<template>
    <div class="datepicker">
        <div class="header">
            <menutabs class="hover-gray" :state="{tabs: tabs}" :tab-selected="tabSelected" @tab-select="UpdateSelectedView"></menutabs>
        </div>
        <component :is="tabSelected.view" :state="{date: date}" @DateSelectionUpdate="DateSelectionUpdate"/>
        <div class="options" v-if="tabSelected && tabSelected.type=='D' && hourlyEnabled">
            <labelc v-for="hour in hourlySelection" :key="hour" :text="hour" v-bind:class="{selected: hourlySelected === hour}" @click="hourlySelected = hour"/>
        </div>
        <div class="footer">
            <div class="align-left">
                <buttonc type="rectangle" label="Abbrechen"  @click="Close" style="width: 90px;"/>
            </div>
            <div class="align-right">
                <buttonc type="rectangle ml" label="Ok"  @click="SelectDate" style="width: 90px;"/>
            </div>
        </div>
    </div>
</template>

<script>
    import calendarview from './calendarview.vue'
    import dayview from './dayview.vue'
    import monthview from './monthview.vue'
    import weekview from './weekview.vue'
    import yearview from './yearview.vue'
    export default {
        components: {
            calendarview,
            dayview,
            monthview,
            weekview,
            yearview,
        },
        name: "datepicker",
        data() {
            return {
                tabs:[
                    
                    {label: 'J', type: 'Y', view: 'yearview', hidden: true, width: '50px'},
                    {label: 'M', type: 'M', view: 'monthview', hidden: true, width: '50px'},
                    {label: 'W', type: 'W', view: 'weekview', hidden: true, width: '50px'},
                    {label: 'T', type: 'D', view: 'dayview', hidden: true, width: '50px'},
                    
                    
                    {label: 'C', type: 'C', view: 'calendarview', hidden: true, width: '50px'},
                ],
                viewsEnabled: ['W','D','M','Y','C'],
                hourlyEnabled: false,
                hourlySelection: [12, 24, 48],
                hourlySelected: 24,
                tabSelected: null,
                date: this.$helpers.getMomentTZStartOfDay(),
            }
        },
        created(){
        },
        props: {
            state: Object,
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];
                    for (let x in this.viewsEnabled){
                        let tab = this.tabs.find(t => t.type === this.viewsEnabled[x]);
                        if(tab){
                            tab.hidden = false;
                        }
                    }

                    let tabSelected = this.tabs.find(t => !t.hidden && t.type === newVal.selectedTab);
                    if(!tabSelected){
                      tabSelected = this.tabs.find(t => !t.hidden)  
                    }
                    if(tabSelected) {
                        this.UpdateSelectedView(tabSelected);
                    }
                }
            }
        },
        methods: {
            UpdateSelectedView(tab){
                this.tabs.forEach(t => t.selected = false);
                tab.selected = true;
                this.tabSelected = tab;
                if(this.$parent.Reposition) this.$parent.Reposition();
            },
            DateSelectionUpdate(date){
                console.log(date._d);
                this.date = date.clone();
            },
            SelectDate(){
                this.$emit('OnDateSelect', this.date, this.hourlySelected);
                let viewString = this.tabSelected.view;
                viewString = viewString[0].toUpperCase() + viewString.slice(1, -4);
                this.$emit('On' + viewString + 'Select', this.date, this.hourlySelected);
                this.$parent.Close();
            },
            Close(){
                this.$parent.Close();
            },
        },
    }
</script>

<style scoped>
    .options{
        float: right;
    }
</style>