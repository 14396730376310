<template>
    <div class="week-view">
        <div class="header">
            <buttonc class="left" type="no-background small" icon="fa-angle-left" @click="ChangeDisplayDate(dateDisplay.add(-1, 'years'))"/>
            <labelc type="header" :text="dateDisplay.isoWeekYear()"></labelc>
            <buttonc class="right" type="no-background small" icon="fa-angle-right" @click="ChangeDisplayDate(dateDisplay.add(1, 'years'))"/>
        </div>
        <div v-for="monthIndex in 12" :key="monthIndex" class="grid">
            <labelc type="header" styleText="font-size: 16px" class="month" :text="dateDisplay.clone().month(monthIndex - 1).date(1).format('MMMM')"/>
            <div class="day" v-for="week in GetWeeksInMonth(monthIndex - 1)" :key="week" v-bind:class="{selected: date.isoWeek() === week && date.year() === dateDisplay.year(), holiday: WeekHolidayCount(dateDisplay.clone().isoWeek(week))}"
                 v-on:click="PickWeek(week)">{{week}}
            </div>
        </div>
    </div>
</template>

<script>
    import day from './day.vue';
    export default {
        components: {
            day,
        },
        name: "weekview",
        date(){
            return {
                date: this.$helpers.getMomentTZStartOfDay(),
                dateDisplay: this.$helpers.getMomentTZStartOfDay(),
                storeEventYearMap: {},
            }
        },
        computed: {

        },
        props:{
            state: Object,
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    if(newVal.date) {
                        this.date = newVal.date.clone().startOf('isoWeek');
                        this.dateDisplay = newVal.date.clone().startOf('isoWeek');
                        this.$forceUpdate();
                    }
                }
            }
        },
        created(){
            this.RefreshStoreEvents();
        },
        methods:{
            RefreshStoreEvents(){
                let view = this;
                if(!this.storeEventMap) this.storeEventMap = {};
                view.loadStoreEvents().then(response => {
                    view.updateStoreEvents(response.data);
                });
            },
            DateSelectionUpdate(date){
                this.date = date.clone();
                this.$forceUpdate();
                this.$emit('DateSelectionUpdate', date.clone());
            },
            ChangeDisplayDate(date){
                this.dateDisplay = date.clone();
                this.RefreshStoreEvents();
                this.$forceUpdate();
            },
            PickWeek(week){
                this.date.year(this.dateDisplay.year()).isoWeek(week).startOf('isoWeek');
                this.DateSelectionUpdate(this.date);
                this.$forceUpdate();
            },
            GetWeeksInMonth(month){
                let start = this.dateDisplay.clone().month(month).date(1);
                let end = start.clone().date(start.daysInMonth());
                let startWeeks = start.isoWeek();
                let endWeeks = end.isoWeek();
                if(endWeeks === 1) endWeeks = start.weeksInYear();
                if(startWeeks > endWeeks) startWeeks = 1;
                let weeks = (endWeeks - startWeeks) + 1;
                if(end.isoWeekday() === 7) weeks++;
                if(start.isoWeekday() === 7) weeks--;
                let array = Array(weeks).fill(0);
                let index = 0;
                for(let x in array){
                    array[x] = startWeeks + index;
                    index++;
                }
                return array;
            },
            WeekHolidayCount(date){
                let view = this;
                if(!date) return false;
                if(!view.storeEventMap[date.year()]) view.storeEventMap[date.year()] = {};
                
                return Object.values(view.storeEventMap[date.year()]).filter(e => e.fromMoment.date() === date.date() && e.fromMoment.month() === date.month() && e.fromMoment.year() === date.year()).length;
            },
            loadStoreEvents: function() {
                let url = '/api/sec/storeevent';
                let params = {
                    storeId: this.$store.state.storeId,
                    active: true,
                    from: this.dateDisplay.clone().startOf('year').add(-1,'year').format(),
                    to: this.dateDisplay.clone().startOf('year').add(2,'year').format()
                };

                return this.axios({
                    method: 'get',
                    params: params,
                    headers: {
                        'AUTHORIZATION': "Bearer "+this.$store.state.jwt
                    },
                    url: url
                });
            },
            updateStoreEvents: function(data){
                let i;
                let tstoreevents = {}
                for (i = 0; i < data.length; i++) {
                    let item = data[i];
                    item.fromMoment = this.$helpers.getMomentFromString(item.from);
                    item.toMoment = this.$helpers.getMomentFromString(item.to);
                    if(!this.storeEventMap[item.fromMoment.year()]) this.storeEventMap[item.fromMoment.year()] = {};
                    tstoreevents[data[i].id] = item;
                }
                if(data.length > 0) this.storeEventMap[tstoreevents[data[0].id].toMoment.year()] = tstoreevents;
                this.$forceUpdate();
            },
        },
    }
</script>

<style scoped>

    .header{

        width: 210px;
        height: 30px;
        justify-content: center;
        align-content: center;
        display: flex;
        margin: 5px 0;
    }
    .grid{
        width: 210px;
        clear: both;
        margin: 5px 0 10px;
    }

    .header{
        position: relative;
        height: fit-content;
    }


    .header-day{
    }

    .header-day .label{
        width: 30px;
        height: 30px;
        margin: 0;
        justify-content: center;
    }

    .header .left,.header .right {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 10;
        padding: 0;
        border-radius: 100px;
        width: 20px;
        height: 20px;
    }

    .header .left{
        left: 5px;
    }

    .header .right {
        right: 5px;
    }


    .grid{
        width: 300px;
        float: left;
        padding: 0 27px;
        text-align: justify;
    }

    .month{
        width: 100px;
        float: none;
        margin-right: 10px;
        display: inline-flex;
        justify-content: flex-end;
    }

    .day{
        float: none;
        display: inline-flex;
        box-shadow: inset 0 0 0 1px var(--contrast-1);
        height: 25px;
        width: 25px;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;
        transition: .2s;
        margin-right: -1px;
    }

    .day:hover{
        background: var(--contrast-1);
    }

    .day.selected{
        background: var(--ml);
        color:white;
    }


    .grid{
        position: relative;
        display: inline-block;
        width: 210px;
        white-space: nowrap;
        margin: 0 0 -10px;
        padding: 0;
        margin-left: -35px;
    }

    .grid:nth-last-child(1){
        margin-bottom: 10px;
    }

    .day.holiday{
        background: var(--contrast-3);
    }
</style>