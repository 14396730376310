<template>
    <labelc class="day" :text="date.format('D')" v-bind:style="{'margin-left': ( (date.isoWeekday() - 1) * 30) + 'px', clear: (date.isoWeekday() === 7) ? 'both' : ''}" v-bind:class="{selected: dateSelected && dateSelected.format('YYYY-MM-DD') === date.format('YYYY-MM-DD')}" @click="Click"/>
</template>

<script>
    export default {
        name: "day",
        props:{
            date: Object,
            dateSelected: Object,
            text: [String, Number],
        },
        methods:{
            Click(){
                this.$emit('click');
            }
        },
    }
</script>

<style scoped>
    .day {
        width: 30px;
        height: 30px;
        cursor: pointer;
    }

    .day *{
        pointer-events: none;
    }

    .day:not(:first-child){
        margin: 0 !important;
    }

    .day:hover{
        background: var(--contrast-1);
    }

    .day.selected{
        background: var(--ml);
        color: white;
    }
</style>