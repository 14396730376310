<template>
    <div class="year-view">
        <div class="header">
            <buttonc class="left" type="no-background small" icon="fa-angle-left" @click="ChangeDisplayDate(dateDisplay.add(-1, 'years'))"/>
            <labelc type="header" :text="dateDisplay.format('YYYY')"></labelc>
            <buttonc class="right" type="no-background small" icon="fa-angle-right" @click="ChangeDisplayDate(dateDisplay.add(1, 'years'))"/>
        </div>
    </div>
</template>

<script>
    export default {
        name: "yearview",
        date(){
            return {
                date: this.$helpers.getMomentTZStartOfDay(),
                dateDisplay: this.$helpers.getMomentTZStartOfDay(),
            }
        },
        props:{
            state: Object
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    if(newVal.date) {
                        this.date = newVal.date.clone();
                        this.dateDisplay = newVal.date.clone();
                        this.$forceUpdate();
                    }
                }
            }
        },
        methods:{
            DateSelectionUpdate(date){
                this.date = date.clone();
                this.$forceUpdate();
                this.$emit('DateSelectionUpdate', date.clone());
            },
            ChangeDisplayDate(date){
                this.dateDisplay = date.clone();
                this.DateSelectionUpdate(date);
                this.$forceUpdate();
            },
        },
    }
</script>

<style scoped>

    .header{

        width: 210px;
        height: 30px;
        justify-content: center;
        align-content: center;
        display: flex;
        margin: 5px 0;
    }
    .grid{
        width: 210px;
        clear: both;
        margin: 5px 0 10px;
    }

    .header{
        position: relative;
        height: fit-content;
    }


    .header-day{
    }

    .header-day .label{
        width: 30px;
        height: 30px;
        margin: 0;
        justify-content: center;
    }

    .header .left,.header .right {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 10;
        padding: 0;
        border-radius: 100px;
        width: 20px;
        height: 20px;
    }

    .header .left{
        left: 5px;
    }

    .header .right {
        right: 5px;
    }

    .month{
        box-shadow: inset 0 0 0 1px var(--contrast-1);
        width: 70px;
        margin: 0;
        cursor: pointer;
        height: 25px;
        display: inline-flex;
    }

    .month:hover{
        background: var(--contrast-1);
    }

    .month.selected{
        background: var(--ml);
        color: white;
    }
</style>