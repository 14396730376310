<template>
    <div class="calendar-view">
        <div class="grid-container" v-for="month in 12" :key="dateDisplay.clone().month(month - 1).format('YYYY-MM-DD')">
            <div class="header">
                <labelc type="header" :text="dateDisplay.clone().month(month - 1).format('MMMM')"></labelc>
            </div>
            <div class="header-day">
                <labelc type="day" text="M"/>
                <labelc type="day" text="T"/>
                <labelc type="day" text="W"/>
                <labelc type="day" text="T"/>
                <labelc type="day" text="F"/>
                <labelc type="day" text="S"/>
                <labelc type="day" text="S"/>
            </div>
            <div class="grid">
                <day v-for="(day, index) in dateDisplay.clone().month(month - 1).daysInMonth()" :key="dateDisplay.clone().month(month - 1).date(day).format('YYYY-MM-DD')" :date="dateDisplay.clone().month(month - 1).date(day)" :date-selected="date" @click="DateSelectionUpdate(dateDisplay.clone().month(month - 1).date(day))"/>
            </div>
        </div>
    </div>
</template>

<script>
    import day from './day.vue';
    export default {
        components: {
            day,
        },
        name: "calendarview",
        date(){
            return {
                date: this.$helpers.getMomentTZStartOfDay(),
                dateDisplay: this.$helpers.getMomentTZStartOfDay(),
            }
        },
        props:{
            state: Object
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    if(newVal.date) {
                        this.date = newVal.date.clone();
                        this.dateDisplay = newVal.date.clone();
                        this.$forceUpdate();
                    }
                }
            }
        },
        methods:{
            DateSelectionUpdate(date){
                this.date = date.clone();
                this.$forceUpdate();
                this.$emit('DateSelectionUpdate', date.clone());
            },
            ChangeDisplayDate(date){
                this.dateDisplay = date.clone();
                this.$forceUpdate();
            },
        },
    }
</script>

<style scoped>

    .calendar-view{
        width: 880px;
    }

    .header{

        width: 210px;
        height: 30px;
        justify-content: center;
        align-content: center;
        display: flex;
        margin: 5px 0;
    }
    .grid{
        width: 210px;
        clear: both;
        margin: 5px 0 10px;
    }

    .header{
        position: relative;
        height: fit-content;
    }


    .header-day{
    }

    .header-day .label{
        width: 30px;
        height: 30px;
        margin: 0;
        justify-content: center;
    }

    .header .left,.header .right {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 10;
        padding: 0;
        border-radius: 100px;
        width: 20px;
        height: 20px;
    }

    .header .left{
        left: 5px;
    }

    .header .right {
        right: 5px;
    }

    .grid-container{
        display: inline-block;
        margin: 0 5px;
        height: 270px;
        float: left;
    }
</style>